import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import Container from "../components/container";
import Layout from "../components/layouts/main-layout";
import Seo from "../components/seo";
import Button from "../components/button";

const VDWSVersicherung = () => {
  const headerImage = (
    <StaticImage
      src="../images/heroimages/tidens_website_safetytool_header.jpg"
      alt="VDWS Safetytool"
      width={2048}
      height={680}
      quality={90}
      className="w-full h-[35vw] min-h-[25vw] max-h-[50vh]"
      objectFit="cover"
    />
  );
  return (
    <Layout>
      <Seo
        title="VDWS Versicherung für den Wassersport"
        description="Das VDWS Safetytool ist deine Versicherung für den Wassersport."
      />
      <div className="bg-slate-500">{headerImage}</div>
      <Container>
        <h1>VDWS SafetyTool - Deine Wassersportversicherung</h1>
        <p>
          In den meisten Haftpflichtversicherungen sind Schäden an geliehenen
          Sportgeräten NICHT mitversichert. Daher empfehlen wir den Abschluss
          einer entsprechenden Versicherung. Das VDWS Safetytool ist speziell
          auf den Wassersport zugeschnitten.
        </p>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 my-6">
          <div className="md:pr-6 ">
            <h2>SafetyTool (39€ / Jahr)</h2>
            <strong>Der klassische Premium-Schutz für den Wassersport.</strong>
            <p>
              Die Grundausstattung für Wassersportler, die sich gerne Material
              ausleihen und ihren Wassersporturlaub sorgenfrei genießen wollen.
              Mit rund 15.000 zufriedenen Versicherten setzt das SafetyTool seit
              über 10 Jahren Standards.
            </p>
            <ul className="list-disc pl-6 mb-4">
              <li>Gemietete Sportgeräte / Boote</li>
              <li>Kaskoschutz für entgeltlich gemietete Sportgeräte</li>
              <li>Haftpflicht-Schutz beim Gebrauch eigener Sportgeräte</li>
              <li>Haftpflicht-Schutz beim Gebrauch gemieteter Sportgeräte</li>
              <li>Unfallversicherung</li>
            </ul>
            <a
              href="./IS_policy_de_2022-03-16.pdf"
              target="_blank"
              className="text-info"
            >
              Produktinformationsblatt
            </a>
            <div className="mt-4">
              <Button
                external="true"
                to="https://cp.vdws.de/safetytool/order/VS24210/IS"
                btntype="secondary"
                title="Versicherung bestellen"
              />
            </div>
          </div>
          <div>
            <h2>SafetyTool PLUS (99€ / Jahr)</h2>
            <strong>
              Premiumschutz für Miet- und Eigenmaterial, sowie für die Familie.
            </strong>
            <p>
              Enthalten sind alle Leistungen des bewährten SafetyTool (außer
              Unfallschutz). Zusätzlich ist hier die Familie und das eigene
              Wassersportmaterial (auch bei Diebstahl) mitversichert.
            </p>
            <ul className="list-disc pl-6 mb-4">
              <li>Familienversicherung</li>
              <li>Gemietete Sportgeräte / Boote</li>
              <li>Kaskoschutz für entgeltlich gemietete Sportgeräte</li>
              <li>Kaskoschutz für eigene Sportgeräte</li>
              <li>Haftpflicht-Schutz beim Gebrauch eigener Sportgeräte</li>
              <li>Haftpflicht-Schutz beim Gebrauch gemieteter Sportgeräte</li>
              <li>Diebstahl und Transportschaden</li>
            </ul>
            <a
              href="./IP_policy_de_2022-03-16.pdf"
              target="_blank"
              className="text-info"
            >
              Produktinformationsblatt
            </a>
            <div className="mt-4">
              <Button
                external="true"
                to="https://cp.vdws.de/safetytool/order/VS24210/IP"
                btntype="secondary"
                title="Versicherung bestellen"
              />
            </div>
          </div>
        </div>
      </Container>
    </Layout>
  );
};

export default VDWSVersicherung;
